#idiomas{
    .idioma{
        border-radius: 1em;
        margin: 0.5em 1em;
        padding-left: 1em;
        /*min-width: 300px;*/
        align-items: center;

        .md-avatar{
            margin-right: 8px;
            width: 48px;
            height: 48px;
        }

        .md-avatar > img{
            max-width: 48px;
            max-height: 48px;
            /*min-width: 40px;*/
            /*min-height: 40px;*/
        }

        .icon-star{
            color: gold;
            margin: auto;
            margin-left: 3px;
        }
    }
}

#editar-idioma {

    .md-avatar{
        margin-right: 8px;
        width: 48px;
        height: 48px;
    }

    .md-avatar > img{
        max-width: 48px;
        max-height: 48px;
    }
}