#usuarios{
    .usuario{
        border-radius: 1em;
        margin: 0.5em 1em;
        padding-left: 1em;
        /*min-width: 300px;*/
        align-items: center;

        .md-avatar{
            margin-right: 8px;
            width: 48px;
            height: 48px;
        }

        .md-avatar > img{
            max-width: 48px;
            max-height: 48px;
            /*min-width: 40px;*/
            /*min-height: 40px;*/
        }

        .icon-star{
            color: gold;
            margin: auto;
            margin-left: 3px;
        }
    }
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-sm) {
    #usuarios{
        md-list-item{
            padding-right: 4px;

            .md-fab{
                margin: 4px;
            }
        }
    }
}

#editarUsuario{
    md-dialog-content{
        padding: 24px;
    }

    .imgContainer{
        align-self: center;
        padding: 4px;
        /*margin-bottom: 1em;*/
        cursor: pointer;

        img{
            max-height: 100px;
            max-width: 100px;
        }
    }

    .imgContainer:hover{
        background: lightgray;
        border-radius: 10px;
    }
}

#elegirAvatar{
    md-grid-tile{
        cursor: pointer;
    }

    md-grid-tile:hover{
        background: lightgray;
    }
}