#instalaciones{
    md-content{
        margin-bottom: 1em;
    }

    md-list-item {
        border-top: 1px solid #cacaca;

        &:last-of-type {
            border-bottom: 1px solid #cacaca;
        }
    }
}

#instalaciones-details{
    table{
        border-collapse: collapse;
        width: 100%;

        td, th {
            border: 1px solid lightgray;
            font-size: 16px;
        }
    }
}

#editar-instalacion {
    table{
        border-collapse: collapse;
        width: 100%;

        input {
            width: 100%;
        }
    }
}